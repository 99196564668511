<template>
  <div class="container">
    <div
      class="left-menu"
      :style="{
        width: !isCollapse ? '12%' : '4.5%',
        textAlign: !isCollapse ? 'left' : 'center',
      }"
    >
      <left-list :leftIndex="'5'" :isCollapse="isCollapse"></left-list>
    </div>
    <div
      class="right-content"
      :style="{ width: !isCollapse ? '88%' : '95.5%' }"
    >
      <div class="top-nav">
        <top-header @openMenu="openMenu" :isCollapse="isCollapse"></top-header>
      </div>
      <div class="left-list">
        <div class="left-list-title">
          <span>用户管理</span>
        </div>
        <div class="left-list-item" @click="leftClick('0')">
          <span>用户列表</span>
        </div>
        <div class="left-list-item left-list-active" @click="leftClick('1')">
          <span>提现管理</span>
        </div>
        <div class="left-list-item" @click="leftClick('2')">
          <span>余额明细</span>
        </div>
      </div>
      <div class="right-next">
        <div class="title">
          <span>审核详情</span>
        </div>
        <div class="progress">
          <div class="progress-item success">
            <div class="stateTxt">
              <span>申请时间</span>
            </div>
            <div class="time">{{ userInfo[0].created_at }}</div>
          </div>
          <div
            class="progress-item"
            :class="userInfo[0].status != 0 ? 'success' : ''"
          ></div>
          <div
            class="progress-item"
            :class="userInfo[0].status != 0 ? 'success' : ''"
          >
            <div class="stateTxt">
              <span>审核时间</span>
            </div>
            <div class="time">{{ userInfo[0].updated_at }}</div>
          </div>
          <div
            class="progress-item"
            :class="userInfo[0].payment == 1 ? 'success' : ''"
          ></div>
          <div
            class="progress-item"
            :class="userInfo[0].payment == 1 ? 'success' : ''"
          >
            <div class="stateTxt">
              <span>{{ userInfo[0].status == 2 ? "已驳回" : "打款时间" }}</span>
            </div>
            <div class="time">
              {{
                userInfo[0].payment_time
                  ? timestampToTime(userInfo[0].payment_time)
                  : userInfo[0].updated_at
              }}
            </div>
          </div>
        </div>
        <div class="title mt-20">
          <span>用户信息</span>
        </div>
        <div class="mt-20">
          <el-table :data="userInfo" style="width: 100%" border>
            <el-table-column
              prop="user_id"
              label="用户ID"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="image"
              label="用户头像"
              width="150"
              align="center"
            >
              <template slot-scope="scope">
                <img
                  style="width: 60%"
                  :src="scope.row.user.avatarUrl"
                  alt=""
                />
              </template>
            </el-table-column>
            <el-table-column
              prop="user.nickName"
              label="用户昵称"
              width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="bank.bank_account"
              label="名称"
              width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="bank.id_card"
              label="身份证号码"
              width="200"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="bank.phone"
              label="手机号"
              width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="bank.bank_name"
              label="开户行"
              width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="bank.bank_card"
              label="银行卡号"
              align="center"
            >
            </el-table-column>
          </el-table>
        </div>
        <div class="title mt-20">
          <span>申请审核</span>
        </div>
        <div class="picture-review">
          <div class="left-region">
            <div v-if="this.userInfo[0].status == 0">
              <el-radio v-model="radio" label="1">通过</el-radio>
              <el-radio v-model="radio" label="2">驳回</el-radio>
              <el-select
                size="mini"
                v-model="imgReject"
                placeholder="驳回原因"
                v-if="radio == 2"
              >
                <el-option
                  v-for="item in remarkList"
                  :key="item.remark"
                  :label="item.remark"
                  :value="item.remark"
                >
                </el-option>
              </el-select>

              <div class="mt-20">
                <el-button type="primary" @click="applySubmit">确定</el-button>
              </div>
            </div>
            <div v-else>
              <p v-if="this.userInfo[0].status == 1">已通过</p>
              <p v-if="this.userInfo[0].status == 2">驳回</p>
              <p class="mt-40" v-if="this.userInfo[0].status == 2">
                驳回理由：{{ this.userInfo[0].remark }}
              </p>
            </div>
          </div>
          <div class="right-region"></div>
        </div>
        <div class="title mt-20" v-if="this.userInfo[0].status == 1">
          <span>打款状态</span>
        </div>
        <div class="mt-20" v-if="this.userInfo[0].status == 1">
          <div v-if="this.userInfo[0].payment == 0">
            <span>未打款</span>
            <el-button
              type="primary"
              size="small"
              class="ml-40"
              @click="paymentClick"
              >确认已打款</el-button
            >
          </div>
          <div v-else>
            <p>已打款</p>
            <p>打款人员：{{ this.userInfo[0].payment_admin_id }}</p>
            <p>打款时间：{{ this.userInfo[0].updated_at }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import leftList from "../../../components/leftList.vue";
import topHeader from "../../../components/header.vue";
export default {
  components: {
    leftList,
    topHeader,
  },
  inject: ["reload"],
  data() {
    return {
      userInfo: [],
      isCollapse: false,
      radio: "1",
      remarkList: [],
      imgReject: "",
    };
  },
  created() {
    this.getInfo();
    this.getRemark();
  },
  methods: {
     timestampToTime(cjsj) {
      var date = new Date(cjsj*1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      console.log(date);
     var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = date.getDate() + " ";
      var h = date.getHours() + ":";
      var m = date.getMinutes() + ":";
      var s = date.getSeconds();
      return Y + M + D + h + m + s;
    },
    applySubmit() {
      if (this.radio == 2) {
        if (this.imgReject == "") {
          this.$notify({
            title: "提示",
            message: "清选择驳回原因",
            type: "warning",
          });
          return false;
        }
      }
      this.$post("check/apply", {
        id: this.userInfo[0].id,
        status: this.radio,
        remark: this.imgReject,
      }).then((res) => {
        if (res.data.status == 200) {
          this.$notify({
            title: "提示",
            message: "操作成功！",
            type: "success",
          });
          this.getInfo();
        } else {
          this.$notify.error({
            title: "提示",
            message: res.data.message,
          });
        }
      });
    },
    getRemark() {
      this.$get("get/remark", {
        num: 999,
      }).then((res) => {
        this.remarkList = res.data.data.data;
      });
    },
    getInfo() {
      this.$get("get/one_apply", {
        id: this.$route.query.id,
      }).then((res) => {
        this.userInfo = [];
        this.userInfo.push(res.data.data);
      });
    },
    paymentClick() {
      this.$confirm("确认修改为确认打款吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("payment/apply", {
            id: this.userInfo[0].id,
            payment: 1,
          }).then((res) => {
            if (res.data.status == 200) {
              this.$notify({
                title: "提示",
                message: "操作成功！",
                type: "success",
              });
              this.getInfo();
            } else {
              this.$notify.error({
                title: "提示",
                message: res.data.message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    remove() {
      if (this.flawList.length > 1) {
        var m = this.flawList.slice();
        m.pop();
        this.flawList = m;
      } else {
        this.$notify.error({
          title: "提示",
          message: "至少声明一条原因",
        });
      }
    },
    add() {
      this.flawList.push({
        inputValue: "",
        imageUrl: "",
      });
    },
    uploadClick(index) {
      this.flawIndex = index;
    },
    handleAvatarSuccess(res, file) {
      this.flawList[this.flawIndex].imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    materialClick() {
      if (this.material == 2) {
        this.centerDialogVisible = true;
      }
    },
    menuitemClick(pushUrl) {
      // console.log(this.$route.path == pushUrl);
      if (this.$route.path == pushUrl) {
        this.reload();
      } else if (pushUrl) {
        this.$router.push(pushUrl);
      } else {
        this.$notify.error({
          title: "提示",
          message: "暂无页面",
        });
        this.reload();
      }
    },
    leftClick(index) {
      if (index == 0 && this.$route.path != "/user") {
        this.$router.push("/user");
      } else if (index == 1 && this.$route.path != "/withdrawal") {
        this.$router.push("/withdrawal");
      } else if (index == 2 && this.$route.path != "/balance") {
        this.$router.push("/balance");
      } else {
        this.reload();
      }
    },
    openMenu() {
      this.isCollapse = !this.isCollapse;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    handleEdit(index, row) {
      this.$router.push({
        name: "knowledgeedit",
        params: {
          row: row,
        },
      });
      //   console.log(index, row);
    },
    handleDelete(index, row) {
      console.log(index, row);
    },
  },
};
</script>

<style lang="scss">
.reason {
  width: 500px;
  display: inline-block;
  .el-input {
    width: 200px;
  }
}
.operation {
  display: inline-block;
  vertical-align: top;
}
.right-content {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  vertical-align: top;
  display: inline-block;
  &::-webkit-scrollbar {
    display: none;
  }
  .physical-examination {
    padding: 0 100px;
  }
  .picture-review {
    // border: 1px solid;
    margin-top: 20px;
    padding: 0 100px;
    .left-region {
      display: inline-block;
      width: 700px;
      .el-input {
        width: 200px;
        margin: 0 20px;
      }
    }
    .right-region {
    }
  }
  .shopimg-list {
    margin-top: 20px;
    img {
      width: 150px;
      height: 150px;
    }
  }
  .shop-table {
    display: inline-block;
    width: 800px;
    padding-top: 20px;
  }
  .img-compress {
    text-align: right;
    span,
    i {
      cursor: pointer;
    }
  }
  .progress {
    margin-top: 25px;
    &-item {
      //   border: 1px solid;
      display: inline-block;
      vertical-align: middle;
      width: calc(100% / 5);
      &:last-child {
        margin-right: 0px;
      }
      &:nth-child(2n) {
        position: relative;
        vertical-align: top;
        margin-top: 17px;
        background-color: rgb(139, 134, 134);
        height: 7px;
        border-radius: 100px;
        &::before {
          content: "";
          width: 12px;
          height: 12px;
          border: solid rgb(139, 134, 134);
          border-width: 0 5px 5px 0;
          transform: translate(-50%, -50%) rotate(-45deg);
          position: absolute;
          right: -10px;
          top: 50%;
        }
      }
      .stateTxt {
        text-align: center;
        span {
          display: inline-block;
          background-color: rgb(139, 134, 134);
          color: #fff;
          padding: 10px 0px;
          width: 200px;
          border-radius: 100px;
        }
      }
      .time {
        text-align: center;
        margin-top: 50px;
      }
    }
    .success {
      .stateTxt {
        span {
          background-color: #27b148;
        }
      }
      &:nth-child(2n) {
        position: relative;
        vertical-align: top;
        margin-top: 17px;
        background-color: #27b148;
        height: 7px;
        border-radius: 100px;
        &::before {
          content: "";
          width: 12px;
          height: 12px;
          border: solid #27b148;
          border-width: 0 5px 5px 0;
          transform: translate(-50%, -50%) rotate(-45deg);
          position: absolute;
          right: -10px;
          top: 50%;
        }
      }
    }
  }
  .date-search {
    margin-top: 30px;
    .el-button {
      margin-left: 40px;
      padding: 12px 55px;
    }
    .el-input {
      width: 200px;
    }
    .left,
    .right {
      display: inline-block;
    }
    .right {
      width: 1000px;
      vertical-align: top;
    }
  }
  .left-list {
    width: 10%;
    height: 93vh;
    overflow-x: hidden;
    overflow-y: scroll;
    font-size: 14px;
    border-right: 20px solid #f2f2f2;
    display: inline-block;
    text-align: center;
    &::-webkit-scrollbar {
      display: none;
    }
    &-title {
      margin: 20px 0;
      font-size: 16px;
    }
    &-item {
      padding: 15px 0;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        color: #fff;
        background-color: #3291f8;
      }
    }
    &-active {
      color: #fff;
      background-color: #3291f8;
    }
  }
  .right-next {
    height: 93vh;
    width: 88.5%;
    overflow-x: hidden;
    overflow-y: scroll;
    border-top: 20px solid #f2f2f2;
    padding: 20px;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .title {
    color: #000;
    height: 10px;
    padding-left: 15px;
    line-height: 10px;
    border-left: 5px solid #80c4f8;
    margin-bottom: 10px;
  }

  .top-nav {
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 15px;

    .left-icon {
      i {
        cursor: pointer;
        color: #3291f8;
        font-size: 30px;
      }

      .el-icon-refresh-right {
        margin-left: 30px;
      }
    }

    .right-user {
      font-size: 14px;

      span {
        vertical-align: middle;
        margin-right: 20px;
      }

      .colse-btn {
        cursor: pointer;
        vertical-align: middle;
        border: 1px solid #999;
        display: inline-block;
        padding: 5px 20px;
        border-radius: 50px;

        span {
          margin-right: 10px;
        }

        i {
          vertical-align: middle;
        }
      }
    }
  }
}

.left-menu {
  display: inline-block;

  .iconyonghuzu {
    font-size: 18px;
    margin: 0 5px;
  }
}

.el-menu {
  width: 100%;
  background-color: #20222a;
  height: 100vh;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  background-color: #20222a;
  color: #fff;
  height: 100vh;
  width: 100%;
}

.el-menu-item:focus,
.el-menu-item:hover {
  color: #000;
  background-color: #ecf5ff;
}

.el-menu-item {
  color: #fff;

  i {
    color: #3291f8;
  }
}

.el-menu-item.is-active {
  background-color: #ecf5ff;
  color: #20222a;
}

.menu-h5 {
  margin: 20px;
  text-align: center;
  font-size: 20px;
}

.menu-icon {
  width: 100%;
  color: #fff;
  box-sizing: border-box;
  padding: 30px 22px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
